import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

import Slider from '../../functions/SliderV2';

import setSpacesInText from '../../functions/setSpacesInText';
import Arrow from '../../components/Arrow.jsx';

class IndexHeader extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            currentBlock: 'JC',
        };

        this.parent = React.createRef();
    }

    setCurrent({ name }) {
        const { items = [] } = this.state;
        const { device } = this.props;
        const currentItem = this.parent.current.querySelector('.aboutHeader__blockItem._current');
        const resultItems = items.filter((item) => item.group === name);
        const [firstItem] = resultItems;
        const nextId = firstItem.id;

        const counts = {
            previousElementSibling: {
                count: device === 'mobile' ? -1 : -2,
                item: null,
            },
            nextElementSibling: {
                count: device === 'mobile' ? -1 : -2,
                item: null,
            },
        };

        ['previousElementSibling', 'nextElementSibling'].forEach((dir) => {
            let nextItem = currentItem[dir];

            counts[dir].count += 1 * (dir === 'previousElementSibling' ? -1 : 1);

            while (nextItem && +nextItem.getAttribute('data-itemId') !== nextId) {
                nextItem = nextItem[dir];
                counts[dir].count += 1 * (dir === 'previousElementSibling' ? -1 : 1);
            }

            counts[dir].item = nextItem;
        });

        let resultItem;

        if (
            Math.abs(counts.previousElementSibling.count) <
            Math.abs(counts.nextElementSibling.count)
        ) {
            resultItem = counts.previousElementSibling.item;
        } else {
            resultItem = counts.nextElementSibling.item;
        }

        resultItem =
            device === 'mobile'
                ? resultItem.previousElementSibling
                : resultItem.previousElementSibling.previousElementSibling;

        const resultIndex = +resultItem.getAttribute('data-id');
        const resultKey = +resultItem.getAttribute('data-key');

        if (this.currentKey !== resultKey) {
            this.slider.clearLoop();

            this.slider.moveToCurrentItem({
                current: resultIndex,
                inertValue: 0,
            });

            this.slider.setLoop();
        }
    }

    sliderInit() {
        const slider = this.parent.current;
        const block = slider.querySelector('.aboutHeader__block');

        const clearAnim = () => {
            const currentInfo = this.parent.current.querySelector(
                '.aboutHeader__blockInfo._current',
            );

            if (currentInfo) {
                const name = currentInfo.querySelector('.aboutHeader__blockName');
                const firstOption = currentInfo.querySelector('.aboutHeader__blockOption._first');
                const lastOption = currentInfo.querySelector('.aboutHeader__blockOption._last');

                name.classList.remove('_anim');
                firstOption.classList.remove('_anim');
                lastOption.classList.remove('_anim');
            }
        };

        const callback = (data) => {
            const { device } = this.props;

            if (data.type === 'startDrag') {
                slider.querySelector('.aboutHeader__blockItems').classList.add('_drag');
            }

            if (data.type === 'endDrag') {
                slider.querySelector('.aboutHeader__blockItems').classList.remove('_drag');
            }

            if (data.type === 'startMove') {
                clearAnim();
            }

            if (data.type === 'startMove' || data.type === 'move' || data.type === 'init') {
                const current = data.current || 0;

                this.currentKey = data.currentKey || 0;

                const item = block.querySelector(`.aboutHeader__blockItem[data-id="${current}"]`);

                const currentItem =
                    device === 'mobile'
                        ? item?.nextElementSibling
                        : item?.nextElementSibling?.nextElementSibling;

                if (currentItem) {
                    const id = +currentItem.getAttribute('data-itemId');

                    block.querySelectorAll('.aboutHeader__blockItem').forEach((innerItem) => {
                        innerItem.classList.remove('_main');
                        innerItem.classList.remove('_active');
                        innerItem.classList.remove('_min');
                        innerItem.classList.remove('_prev');
                        innerItem.classList.remove('_next');
                    });

                    currentItem.classList.add('_main');

                    const prevItem = currentItem.previousElementSibling;
                    const nextItem = currentItem.nextElementSibling;

                    if (prevItem) {
                        const prev2Item = prevItem.previousElementSibling;

                        prevItem.classList.add('_active');
                        prevItem.classList.add('_prev');

                        if (prev2Item) {
                            prev2Item.classList.add('_active');
                            prev2Item.classList.add('_min');
                        }
                    }

                    if (nextItem) {
                        const next2Item = nextItem.nextElementSibling;

                        nextItem.classList.add('_active');
                        nextItem.classList.add('_next');

                        if (next2Item) {
                            next2Item.classList.add('_active');
                            next2Item.classList.add('_min');
                        }
                    }

                    this.setState({ currentId: id, aboutCurrentId: null }, () => {
                        const currentInfo = this.parent.current.querySelector(
                            '.aboutHeader__blockInfo._current',
                        );
                        const name = currentInfo.querySelector('.aboutHeader__blockName');
                        const firstOption = currentInfo.querySelector(
                            '.aboutHeader__blockOption._first',
                        );
                        const lastOption = currentInfo.querySelector(
                            '.aboutHeader__blockOption._last',
                        );

                        name.classList.add('_anim');

                        setTimeout(() => {
                            firstOption.classList.add('_anim');
                        }, 150);

                        setTimeout(() => {
                            lastOption.classList.add('_anim');
                        }, 300);
                    });
                }
            }
        };

        this.slider = new Slider({
            slider,
            area: slider.querySelector('.aboutHeader__blockItemsBoxInner'),
            moveArea: slider.querySelector('.aboutHeader__blockItems'),
            itemClass: 'aboutHeader__blockItem',
            current: 0,
            infinity: true,
            showEach: true,
            // loop: 3_000,
            buttons: {
                prev: slider.querySelector('.aboutHeader__blockBtn._prev'),
                next: slider.querySelector('.aboutHeader__blockBtn._next'),
            },
            callback,
        });
    }

    checkInit() {
        const { components } = this.props;

        if (components && !this.isInit) {
            this.isInit = true;
            const { superdrink = [], superjuice = [] } = components;
            const items = [
                ...superdrink.map((item) => ({ ...item, group: 'SD' })),
                ...superjuice.map((item) => ({ ...item, group: 'JC' })),
            ].map((item, key) => ({ ...item, id: key }));

            this.setState({ items }, () => {
                this.sliderInit();
            });
        }
    }

    componentDidMount() {
        this.checkInit();
    }

    componentDidUpdate() {
        this.checkInit();
    }

    componentWillUnmount() {
        if (this.slider) {
            this.slider.destroy();
        }
    }

    render() {
        const { currentId, aboutCurrentId = null, items = [] } = this.state;
        const { device, components } = this.props;
        const currentItem = items.find((item) => item.id === currentId);
        const banner = components?.banner;

        return (
            <>
                <div ref={this.parent} className={`aboutHeader`}>
                    <div className="aboutHeader__inner">
                        <div className="aboutHeader__content">
                            <div
                                className={`aboutHeader__block ${
                                    aboutCurrentId !== null ? '_aboutActive' : ''
                                }`}
                            >
                                {items.map((item, index) => {
                                    const { id } = item;
                                    const isCurrent = id === currentId;
                                    const currentIndex = items.findIndex(
                                        (innerItem) => innerItem.id === currentId,
                                    );

                                    return (
                                        <>
                                            {device === 'mobile' && (
                                                <div
                                                    className={`aboutHeader__blockMobAbout ${
                                                        isCurrent ? '_current' : ''
                                                    }`}
                                                    onClick={() => {
                                                        this.setState({ aboutCurrentId: id });

                                                        this.slider.clearLoop();
                                                    }}
                                                >
                                                    Состав
                                                    <div className="aboutHeader__blockMobAboutIcon"></div>
                                                </div>
                                            )}

                                            <div
                                                className={`aboutHeader__blockInfo ${
                                                    isCurrent ? '_current' : ''
                                                } _${item.group} ${
                                                    index > currentIndex ? '_next' : ''
                                                } ${index < currentIndex ? '_prev' : ''}`}
                                                key={id}
                                            >
                                                <div className="aboutHeader__blockName">
                                                    <b>
                                                        {item.group === 'JC'
                                                            ? 'Супер Джус'
                                                            : 'Супер Дринк'}
                                                    </b>
                                                    {item.title}
                                                </div>
                                                <div className="aboutHeader__blockOptions">
                                                    <div className="aboutHeader__blockOption _first">
                                                        {item.extra1}
                                                    </div>
                                                    <div className="aboutHeader__blockOption _last">
                                                        {item.extra2}
                                                    </div>
                                                </div>
                                            </div>
                                            <div
                                                className={`aboutHeader__blockAbout ${
                                                    isCurrent ? '_current' : ''
                                                } _${item.group} ${
                                                    aboutCurrentId === item.id ? '_active' : ''
                                                }`}
                                            >
                                                {device === 'mobile' && (
                                                    <img
                                                        src={
                                                            require('../../media/info-sostav-close-mob.svg')
                                                                .default
                                                        }
                                                        alt=""
                                                        className="aboutHeader__blockAboutClose"
                                                        onClick={() => {
                                                            this.setState({ aboutCurrentId: null });

                                                            this.slider.setLoop();
                                                        }}
                                                    />
                                                )}
                                                <div
                                                    className="aboutHeader__blockAboutInner"
                                                    dangerouslySetInnerHTML={{
                                                        __html: setSpacesInText(item.description),
                                                    }}
                                                ></div>
                                            </div>
                                        </>
                                    );
                                })}

                                <Arrow
                                    name="prev"
                                    className="aboutHeader__blockBtn _prev"
                                    white={true}
                                />
                                <Arrow
                                    name="next"
                                    className="aboutHeader__blockBtn _next"
                                    white={true}
                                />

                                <div className="aboutHeader__blockItemsBox">
                                    <div className="aboutHeader__blockItemsBoxInner">
                                        <div className="aboutHeader__blockItems">
                                            {items.map((item) => (
                                                <div
                                                    className={`aboutHeader__blockItem _${item.group}`}
                                                    key={item.id}
                                                    data-itemId={item.id}
                                                >
                                                    <div className="aboutHeader__blockItemInner">
                                                        <div className="aboutHeader__blockItemWrapper">
                                                            <img
                                                                src={item.thumb}
                                                                alt=""
                                                                className="aboutHeader__blockItemImage"
                                                            />
                                                        </div>
                                                    </div>
                                                </div>
                                            ))}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="aboutHeader__buttons">
                            <div className="aboutHeader__buttonsInner">
                                <div
                                    className={`aboutHeader__button ${
                                        currentItem?.group === 'JC' ? '_current' : ''
                                    }`}
                                    onClick={() => {
                                        this.setCurrent({ name: 'JC' });
                                    }}
                                >
                                    <img
                                        src={require('../../media/logo-SJ.svg').default}
                                        alt=""
                                        className="aboutHeader__buttonImage"
                                    />
                                </div>
                                <div
                                    className={`aboutHeader__button ${
                                        currentItem?.group === 'SD' ? '_current' : ''
                                    }`}
                                    onClick={() => {
                                        this.setCurrent({ name: 'SD' });
                                    }}
                                >
                                    <img
                                        src={require('../../media/logo-SD.svg').default}
                                        alt=""
                                        className="aboutHeader__buttonImage"
                                    />
                                </div>
                            </div>
                        </div>
                        <div className="aboutHeader__aboutWrapper">
                            <div className="aboutHeader__about">
                                <div className="aboutHeader__aboutBlock _image">
                                    <img
                                        src={require('../../media/juice-skate.png')}
                                        alt=""
                                        className="aboutHeader__aboutImage"
                                    />
                                </div>
                                <div className="aboutHeader__aboutBlock _content">
                                    <div
                                        className="aboutHeader__aboutTitle"
                                        dangerouslySetInnerHTML={{ __html: banner?.info?.title }}
                                    ></div>
                                    <div className="aboutHeader__aboutPreview">
                                        <img
                                            src={banner?.info?.thumb}
                                            alt=""
                                            className="aboutHeader__aboutPreviewImage"
                                        />
                                    </div>
                                </div>
                                <div className="aboutHeader__aboutBlock _about">
                                    <div className="aboutHeader__aboutInfo">
                                        <div className="aboutHeader__aboutInfoTitle">
                                            {banner?.extra?.title}
                                            {/* ACTIVE KID <span>*</span> */}
                                        </div>
                                        <div
                                            className="aboutHeader__aboutInfoDescription"
                                            dangerouslySetInnerHTML={{
                                                __html: setSpacesInText(banner?.extra?.description),
                                            }}
                                        ></div>
                                    </div>
                                    <div className="aboutHeader__aboutBlockSupport">
                                        {banner?.extra?.tips}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </>
        );
    }
}

function mapStateToProps(state) {
    return {
        device: state.device,
        user: state.user,
    };
}

export default connect(mapStateToProps)(IndexHeader);

IndexHeader.propTypes = {
    device: PropTypes.string,
    user: PropTypes.object,
    components: PropTypes.object,
};
