import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

import Link from './Link.jsx';
import Animate from './Animate.jsx';
import getNav from '../functions/getNav';
import { dispatcher } from '../redux/redux';

class MobileMenu extends React.Component {
    constructor(props) {
        super(props);
        this.state = {};

        this.parent = React.createRef();
    }

    keys = {
        index: 'main',
        rules: 'rules',
        prizes: 'prizes',
        about: 'about',
        winners: 'winners',
        faq: 'faq',
    };

    render() {
        const { mobileMenuShow, user, mainContent } = this.props;

        const hideMenu = () => {
            setTimeout(() => {
                dispatcher({
                    type: 'mobileMenuShow',
                    data: false,
                });
            }, 100);
        };

        return (
            <Animate className="body__mobileMenu" isShow={mobileMenuShow}>
                <div className="mobileMenu">
                    <div className="mobileMenu__inner">
                        <div className="mobileMenu__content">
                            <div className="mobileMenu__nav">
                                {getNav({ user }).map((item) => {
                                    const { name, isAncor } = item;
                                    const LinkTag = isAncor ? 'div' : Link;
                                    const linkProps = isAncor
                                        ? {
                                              onClick: () => {
                                                  document.dispatchEvent(
                                                      new CustomEvent('indexAncor', {
                                                          detail: { name, changeIsHard: true },
                                                      }),
                                                  );

                                                  hideMenu();
                                              },
                                          }
                                        : {
                                              pageName: name,
                                              changeIsHard: !item.isPopup,
                                              prevActions: () => {
                                                  hideMenu();
                                              },
                                          };

                                    const key = this.keys[name];
                                    const menuObj = mainContent.header?.menu || {};
                                    const menu = Object.keys(menuObj).map(
                                        (itemKey) => menuObj[itemKey],
                                    );
                                    const content =
                                        menu?.find((menuItem) => menuItem.url === key)?.title ||
                                        item.content;

                                    return (
                                        <div className="mobileMenu__navItem" key={name}>
                                            <LinkTag className="mobileMenu__navLink" {...linkProps}>
                                                {content}
                                            </LinkTag>
                                        </div>
                                    );
                                })}
                                <div className="mobileMenu__navItem">
                                    <a
                                        className="mobileMenu__navLink _frend"
                                        href="https://game.dobry-kids.ru"
                                        target="_blank"
                                        rel="noreferrer"
                                    >
                                        ФрендзаниЯ
                                    </a>
                                </div>
                            </div>
                            {/* <div className="mobileMenu__buttons">
                                <Link
                                    className="mobileMenu__button"
                                    href="login"
                                    prevActions={hideMenu}
                                >
                                    <Button className="_yellow">
                                        {mainContent?.header?.buttons?.button2?.title ||
                                            'Личный кабинет'}
                                    </Button>
                                </Link>
                            </div> */}
                        </div>
                    </div>
                </div>
            </Animate>
        );
    }
}

function mapStateToProps(state) {
    return {
        mobileMenuShow: state.mobileMenuShow,
        mainContent: state.mainContent,
        user: state.user,
    };
}

export default connect(mapStateToProps)(MobileMenu);

MobileMenu.propTypes = {
    mobileMenuShow: PropTypes.bool,
    mainContent: PropTypes.object,
    user: PropTypes.object,
};
